@font-face {
  font-family: 'BwModelica';
  src: url('assets/fonts/bw_modelica/eot/BwModelica-Regular.eot'); 
  src: url('assets/fonts/bw_modelica/eot/BwModelica-Regular.eot#iefix') format('embedded-opentype'), 
       url('assets/fonts/bw_modelica/woff/BwModelica-Regular.woff') format('woff'); 
  font-weight: normal; 
}

@font-face {
  font-family: 'BwModelica';
  src: url('assets/fonts/bw_modelica/eot/BwModelica-Bold.eot'); 
  src: url('assets/fonts/bw_modelica/eot/BwModelica-Bold.eot#iefix') format('embedded-opentype'), 
       url('assets/fonts/bw_modelica/woff/BwModelica-Bold.woff') format('woff'); 
  font-weight: bold; 
}

@font-face {
  font-family: 'BwModelica';
  src: url('assets/fonts/bw_modelica/eot/BwModelica-ExtraBold.eot'); 
  src: url('assets/fonts/bw_modelica/eot/BwModelica-ExtraBold.eot#iefix') format('embedded-opentype'), 
       url('assets/fonts/bw_modelica/woff/BwModelica-ExtraBold.woff') format('woff'); 
  font-weight: 800; 
}

*{
  box-sizing: border-box;
}


body{
  overflow-x:hidden; /* Contetti needs this */
  font-family: 'BwModelica';
  font-weight: normal;
  line-height: 1.4375;
  font-size: 16px;
  color:#000;
  background: #f6f6f6;
}


strong{
  font-weight: 800;
}


img{
  max-width: 100%;
  height: auto;
}

.app{
  margin:0 auto;
  width: 100%;
  max-width: 600px;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow: visible;
}



.cancel-content{
  padding: 0;
  text-align: right;
  font-size: 14px;
}

.cancel-content a{
  text-decoration: none;
  color: currentColor;
  font-weight: normal;
}

.app-inner{
  text-align: center;
  padding: 20px 20px 30px 20px;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);


  overflow: visible;
}

.app-bottom{
  padding-top:30px;
  margin-top:auto;
}

.app-loader{
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding-bottom:20vh;
}

button,
a.button{
  font-family: 'BwModelica';
}

h2{
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.45;
  margin-bottom:20px;
}

h2,h3,h4,p{
  margin-top:10px;
  margin-bottom: 20px;
}





/* Buttons */

.button,
.option-form button,
a.button{
  background: #FFFFFF;
  /* border: 3px solid #02DF8F; */
  border: 3px solid #fff;
  box-sizing: border-box;
  border-radius: 100px;
  font-size: 12px;
  color:#000;
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding:13px 40px;
  width: 100%;
  /*filter: drop-shadow(0px 10px 21px rgba(0, 0, 0, 0.2)); */
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.2);
}

a.button{
  text-decoration: none;
  display: block;
}

.button.selected,
button.selected{
  border-color: #02DF8F;
}

.button--submit,
a.button--submit{
  background: #4B36CC;
  color:#fff;
  border-color:#4B36CC;
}

.button--black,
a.button--black{
  background: #000;
  color:#fff;
  border-color:#000;
}

.button--margintop,
a.button--margintop{
  margin-top: 10px;
}

.button--marginbottom,
a.button--marginbottom{
  margin-top: 10px;
}

/* Start */

.instructions dt div{
  width: 60px;
  height: 60px;
  border-radius: 100%;
  color:#fff;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content:center ;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.2);
  margin-bottom:10px;
}

.instructions dd{
  font-weight: bold;
  font-size: 23px;
  line-height: 1.39;
  margin-bottom:25px;
}

@media screen and ( max-height: 520px ){
  
  .instructions dt div{
    width: 30px;
    height: 30px;
    font-size: 14px;
    margin-bottom:5px;
  }

  .instructions dd{
    font-weight: bold;
    font-size: 18px;
    margin-bottom:15px;
  }

}

/* Hints */

.hint{
  display: flex;
  line-height: 1px;
  margin-top:70px;
}

.hint__item--no{
  margin-right: 7px;
}


.hint__item--yes{
  margin-left: 7px;
}

.hint__item{
  position: relative;
}

.hint__symbol{
  position: absolute;
  right: 7px;
  bottom:8px;
}


/* Webcam */

.webcam-wrap{
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100); 
  width: 100%;
  display: flex;
  flex-direction: column;
}

.webcam-hint{
  background: #02DF8F;
  font-weight: 800;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  padding:15px 20px;
  width: 100%;
}

.webcam-webcam{
  width: 100%;
  flex-grow:1;
  position: relative;
  overflow: hidden;
}

.webcam-webcam video{
  max-width: 100% !important;
  max-height: 100% !important;
  
}

.webcam-capture{
  text-align: center;
  position: absolute;
  left: 20px;
  bottom:40px;
  right: 20px;
}

.webcam-overlay{
  line-height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  top:0;
  bottom:0;
  display:flex;
  justify-content: center;

}

.webcam-overlay-mask{
  width: 99%;
  max-width:100;
}

.webcam-overlay-noglasses{
  position: absolute;
  left: 15px;
  top:15px;
}

.webcam-overlay-noglasses svg{
}

.webcam-overlay-mask img{
  width: 100%;
  height: auto;
}



.button--capture{
  width: auto;
}

.webcam-loader{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background:rgba(0, 0, 0, 0.7)
}

/* Select screens */

.pin-visible,

.pin-visible{
  pointer-events: none;
}

.option-form button{
  margin-bottom:10px;
}

.option-form--icons button{
  width: initial;
  margin-right: 5px;
  margin-left: 5px;
  width: calc(33.33% - 10px);
  border-radius: 6px;
  padding-left:10px;
  padding-right: 10px;
  text-align: center;
  letter-spacing: 0;
  font-size: 10px;
}

.option-form--icons button img{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.option-form--images{
  display: flex;
  flex-wrap: wrap;
}

.option-form--images button{
  height: auto;
  width: initial;
  background:transparent;
  margin-right: 5px;
  margin-left: 5px;
  width: calc(50% - 10px);
  border: 3px solid transparent;
  border-radius: 6px;
  padding:0;
  line-height: 1px;
  text-align: center;
  letter-spacing: 0;
  font-size: 10px;
  filter:none;
  box-shadow:none;
  border-radius: 0;
}



.option-form--images button img{
  max-width: 100%;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
}

.option-form--images button.selected img{
  border: 3px solid #02DF8F;

}

/* Results */

.app-inner--results{
  padding-bottom: 170px;
}

.pin-invisible{
  display: none;
}

.pin-visible>span,
.pin-invisible > span{
  /*filter: drop-shadow(0px 10px 21px rgba(0, 0, 0, 0.2)); */
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.2);
}

.results-cta{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  border-top: 1px solid #D3D3D3;
  padding: 15px 20px 30px 20px;
  transition:opacity 1s;
}

.results-cta--hidden{
  opacity: 0;
  pointer-events: none;
}

.results-cta label{
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  margin-bottom:10px;
}

.results-cta__buttons{

  display: flex;
  justify-content: space-between;
  max-width: 285px;
  margin-left: auto;
  margin-right: auto;
}


.results-cta .button-yes,
.results-cta .button-no{
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 800;
  background:none;
  border:none;
  padding:10px 20px;
}

.results-cta .button-yes{
  color:#02DF8F;
}

.results-cta .button-no{
  color:#FF715E;
  
}

/* Share */

.button--invite{
  margin-bottom:10px;
}


.share-results{
  overflow: hidden;
  transition: max-height 2s ease-in;
  max-height: 0px;
  opacity:0;
  padding-bottom:0px;
}

.share-results--visible{
  max-height: 9999px;
  opacity:1;
  padding-bottom:20px;

  /* Fix for drop shadows being cut off in overflow hidden */

  padding: 0 50px 50px 50px;
  margin:0px -50px -30px -50px;

}

/* Feedback form */

.feedback-form{
  text-align: center;
  padding: 20px 20px 30px 20px;
  display: flex;
  flex-flow: column nowrap;
  overflow: visible;
}

.feedback-form input[type=email],
.feedback-form textarea{
  background: #FFFFFF;
  border: 1px solid #B7B7B7;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 16px;
}


.feedback-form input[type=email]{

  padding:13px 20px;
}

.feedback-form textarea{
  min-height: 70px;
 
}

.feedback-form textarea.error,
.feedback-form input.error{
  border-color:red;
}

.submit-form__label{
  font-size: 10px;
  text-transform: uppercase;
  margin-top:20px;
  margin-bottom:20px;
}